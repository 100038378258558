<script>
import PrivacyForm from "@/views/menu/privacy/privacyForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    privacyService: "privacyService",
  },
})
export default class PrivacyNew extends mixins(PrivacyForm) {
  formId = "new-privacy-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }
  async save(passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      await this.privacyService.create({
        code: this.model.code,
        type: this.model.type,
        required: this.model.required,
        localizations: this.model.localizations,
      });
      this.$router.push("/privacy/list");
      toastService.success(this.translations.success.privacy_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.privacy_new;
  }

  afterCreate () {
    this.model = {
      code: null,
      type: null,
      required: false,
      localizations: [],
    };
    this.isReady = true;
  }
}
</script>
